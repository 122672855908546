<template>
  <Transition name="scale_fade">
    <div id="event_popup" v-if="isOpen && content">
      <div id="window">
        <svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
        <div id="text" v-html="content"></div>
        <div id="close_button_container">
          <button @click="close">Bezárás</button>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      content: "",
    }
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    async fetchPopupContent() {
      const fileUrl = "https://www.allatszervizkft.hu/event_popup.txt";

      try {
        const response = await fetch(fileUrl, { cache: "no-store" });

        if (response.ok) {
          const responseContent = await response.text();
          this.content = responseContent;

          if (this.content) { // Tartalom rendben van
            this.isOpen = true;
            console.log("EventPopup content successfuly fetched")
          }
          else { // Tartalom üres
            console.log("EventPopup content is empty");
          }
        }
        else { // HTTP response nem ok
          console.log("EventPopup content repsonse not ok: ", response);
        }
      }
      catch (error) { // Hiba a tartalom lekérésekor
        console.log("Error fetching EventPopup content: ", error);
      }
    }
  },
  mounted() {
    this.fetchPopupContent();
  }
}
</script>

<style scoped>
/* #region Transition formázás */
.scale_fade-enter-active,
.scale_fade-leave-active {
  transform: scale(1);
  opacity: 1;
  transition: transform 0.33s ease, opacity 0.33s ease;
}

.scale_fade-enter-from,
.scale_fade-leave-to {
  transform: scale(1.5);
  opacity: 0;
}

.scale_fade-enter-active,
.scale_fade-leave-active,
.scale_fade-enter-from,
.scale_fade-leave-to {
  position: absolute;
}
/* #endregion */

/* #region Tartalom formázás */
::v-deep #text span.h_closed {
  font-weight: bold;
  color: white;
  background-color: #ff6868;
  text-shadow: red 0 0 3px;
  padding: 3px;
}

::v-deep #text ul {
  padding-left: 15px;
}

::v-deep #text li {
  margin-top: 5px;
}
/* #endregion */

/* #region Popup formázás */
#event_popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 101;
}

#event_popup #window {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 0 15px black;
  width: 95vw;
  height: max-content;
  max-width: 700px;
  max-height: 90vh;
  position: fixed;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-x: hidden;
}

#event_popup #icon, #event_popup #text {
  display: inline-block;
  width: calc(100% - 110px);
}

#event_popup #icon {
  fill: orange;
  height: 72px;
  vertical-align: top;
  margin-top: 30px;
  margin-right: 5px;
  padding-left: 5px;
  display: inline-block;
  width: max-content;
}

#event_popup #text {
  padding: 10px;
  padding-top: 15px;
  color: hsl(0, 0%, 30%);
}

#event_popup h1 {
  color: hsl(0, 0%, 25%);
  margin-bottom: 0;
  margin-top: 0;
}

#event_popup p {
  margin: 7px 0;
}

#event_popup ul {
  margin: 0;
}

#event_popup a {
  color: #07B0AD;
}

#event_popup a:hover {
  text-shadow: hsl(178, 48%, 25%) 0 0 5px;
  background-color: rgba(88, 197, 193, 0.5);
  color: white;
  text-decoration: none;
}

#event_popup a:active {
  background-color: #58c5c2;
  text-shadow: none;
  color: black;
  text-decoration: none;
}

#event_popup #links_ul {
  
  padding-left: 25px;
}

#event_popup #close_button_container {
  background-color: hsl(0, 0%, 90%);
  border-top: hsl(0, 0%, 80%) solid 3px;
  padding: 5px;
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 10;
}

#event_popup #close_button_container button {
  border: none;
  background-color: #07b0ad;
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding: 8px;
  border-radius: 10px;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  transition: background-color 0.1s, color 0.1s;
}

#event_popup #close_button_container button:hover {
  background-color: hsl(179, 69%, 29%);
}

#event_popup #close_button_container button:active {
  background-color: hsl(0, 0%, 75%);
  color: black;
}

#event_popup #close_button_container span {
  display: block;
  color: hsl(0, 0%, 50%);
  font-size: 12px;
  text-align: center;
  width: 100%;
  margin-top: 5px;
  cursor: default;
}
/* #endregion */

@media only screen and (max-width: 650px) {
  #event_popup #icon {
    display: block;
    width: 100%;
    margin-inline: auto;
    margin-block: 0;
  }

  #event_popup #icon {
    display: block;
    width: 100%;
    margin-top: 10px;
  }

  #event_popup #text {
    display: block;
    width: calc(100% - 20px);
  }

  ::v-deep #text h1 {
    text-align: center;
    margin: 0 0 10px 0;
  }
}
</style>