<template>
  <div :class="{navbar_links: true, compact_mode: compactMode, expanded: compactModeExpanded}">
    <button class="hamburger_menu_btn" v-if="compactMode" @click="toggleLinksExpanded">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>
    </button>
    <div class="links_container" ref="container">
      <div class="link_container" v-for="link in links" :key="link.text">
        <router-link v-if="!link.isExternalLink" :class="{rightmost_link: link.rightmost}" :to="link.href" @click="setLinksExpanded(false)">
          <span class="link-text">{{link.text}}</span>
          <div class="bg"></div>
        </router-link>

        <a v-else :class="{rightmost_link: link.rightmost}" :href="link.href" target="_blank" @click="setLinksExpanded(false); hetvegiKeszenletAlert();">
          <span class="link-text">{{link.text}} <svg class="external_link_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/></svg></span>
        </a>
      </div>
    </div>
    <div :class="{expanded_bg: true, expanded_bg_hide: !showExpandedBg}" v-if="compactMode" @click="setLinksExpanded(false)"/>
  </div>
</template>
<script>
export default {
  name: "NavbarLinks",
  data() {
    return {
      compactMode: false,
      compactModeExpanded: false,
      showExpandedBg: false,
      containerWidth: -1,
      links: [
        {
          text: "Kezdőlap",
          href: "/",
          isExternalLink: false
        },
        {
          text: "Bemutatkozás",
          href: "/bemutatkozas",
          isExternalLink: false
        },
        {
          text: "Szolgáltatások",
          href: "/szolgaltatasok",
          isExternalLink: false
        },
        {
          text: "Tudományos munkák",
          href: "/tudomanyos_munkak",
          isExternalLink: false
        },
        {
          text: "GYIK",
          href: "/gyik",
          isExternalLink: false
        },
        {
          text: "Ügyelet",
          href: "https://allatorvosugyeletzeg.weebly.com/",
          isExternalLink: true,
        },
        {
          text: "Céginfó",
          href: "/ceginfo",
          isExternalLink: false
        },
        {
          text: "Elérhetőség",
          href: "/elerhetoseg",
          isExternalLink: false,
          rightmost: true
        }
      ]
    }
  },

  methods: {
    updateCompactMode() {
      const PADDING = 10
      this.compactMode = window.innerWidth < this.containerWidth + PADDING;
      this.$emit("compactModeUpdated", this.compactMode)
    },

    setLinksExpanded(n) {
      if (n) {
        this.showExpandedBg = true;
        
        document.body.classList.add("disable_scroll");
        document.body.scrollTo(0, 0);
      }
      else {
        setTimeout(() => {
          this.showExpandedBg = false;
        }, 500);

        document.body.classList.remove("disable_scroll");
      }

      this.compactModeExpanded = n;
    },

    toggleLinksExpanded() {
      this.setLinksExpanded(!this.compactModeExpanded)
    },

    // Undorítóan hackelt megoldás, de ideiglenesen és gyorsan kell, szóval ez lesz.
    hetvegiKeszenletAlert() {
      alert("A hétvégi készenléti ügyelet 2025.01.01-től praxisonként eltérően alakul:\n- A Vadóc Állatorvosi Rendelő szabad-, munkaszüneti és ünnepnapot megelőző munkanapon 20:00-tól az azt követő első munkanap 6:00-ig tart ügyeletet.\n- Az Állatszerviz Kft. és a Zalai Állatgyógyászati Rendelő/Trivet Kft. szabad-, munkaszüneti és ünnepnapokon 8:00-22:00 óráig tart ügyeletet.\n- Az aktuális ügyeletes állatorvos nevét az ügyeleti weboldalon találja meg.");
      window.open("https://allatorvosugyeletzeg.weebly.com/", "_blank"); // A linkre kattintást popupként blokkolja a legtöbb böngésző, ezért kell ez ide, de így a főbb böngészőkben (Firefox, Chrome, Edge-ben teszteltem) tökéletesen pontosan egyszer nyitja meg (és egyet blokkol)
    }
  },

  mounted() {
    // Amikor az oldal teljesen betöltött.
    // Amikor a `mounted()` lefut, akkor még (gondolom) nem töltötte be teljesen a CSS-t, és kicsit keskenyebb szélességet ad vissza, mint valójában.
    window.onload = () => {
      window.addEventListener('resize', this.updateCompactMode);
      this.containerWidth = Math.round(this.$refs.container.getBoundingClientRect().width);
      this.updateCompactMode();
    };
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.updateCompactMode);
  }
}
</script>

<style scoped>
/* #region Normal mode */
.links_container { /* Class, mert a `links_overflowing` feltélese class-t az id felülírná (és nem lehet kindicionális id-t csinálni) */
  float: right;
  position: relative;
  vertical-align: top;
  width: max-content;
  height: 30px;
  overflow-y: hidden;
  white-space: nowrap;
  background-color: #002930;
  border: #4b858f solid 2px;
  border-radius: 15px;
  margin-top: 3px;
  margin-right: 10px;
  padding-top: 10px;
}

.links_container .link_container {
  float: left;
}

.links_container a {
  display: inline-block;
  position: relative;
  vertical-align: top;
  margin: -6px 10px 0 4px;
  padding: 4px 2px;
  z-index: 1;
}

.links_container .rightmost_link {
  margin-right: 5px;
}

.links_container a .link-text {
  display: inline-block;
  position: relative;
  font-family: 'Rubik', sans-serif;
  font-size: 20px;
  color: white;
  text-decoration: none;
  transition: color 0.2s;
  z-index: 2;
}

.links_container a:hover .link-text {
  color: #4b858f;
}

.links_container a:hover .external_link_icon {
  fill: #4b858f;
}

.links_container a .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  margin-left: 50%;
  height: 100%;
  background-color: hsl(0, 0%, 94%);
  border-radius: 10px;
  transition: width 0.2s, margin-left 0.2s;
  z-index: 1;
}

.links_container a.router-link-active .bg {
  width: 100%;
  margin-left: 0%;
}

.links_container a.router-link-active .link-text {
  color: #002930;
}

.external_link_icon {
  display: inline-block;
  height: 18px;
  vertical-align: middle;
  margin-left: 2px;
  margin-bottom: 5px;
  fill: white;
  transition: fill 0.2s;
}
/* #endregion */

/* #region Compact mode */
.hamburger_menu_btn {
  position: relative;
  z-index: 101;
  top: 5px;
  float: right;
  margin-right: 10px;
  width: 40px;
  height: 40px;

  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  cursor: pointer;
}

.hamburger_menu_btn svg {
  fill: #58C5C2;
  margin-top: 1px;
}

.navbar_links.compact_mode.expanded .hamburger_menu_btn {
  background-color: #58C5C2;
  border-color: transparent;
}

.navbar_links.expanded .hamburger_menu_btn svg {
  fill: #002930;
}

.navbar_links.compact_mode .links_container {
  position: fixed;
  z-index: 100;
  right: 0;
  top: 50px;
  transform: translateX(100%);
  border: none;
  border-radius: 0;
  margin: 0;
  height: calc(100vh - 60px);
  transition: transform 0.5s;
}

.navbar_links.compact_mode.expanded .links_container {
  transform: translateX(0);
}

.navbar_links.compact_mode .link_container {
  float: none;
  display: block;
  margin: 0 10px 10px 5px
}

.navbar_links .expanded_bg {
  position: absolute;
  z-index: 99;
  top: 50px; /* Navbar magassága */
  left: 0;
  width: 100vw;
  height: calc(100vh - 50px);
  background-color: transparent;
  transition: background-color 0.5s;
}

.navbar_links .expanded_bg.expanded_bg_hide {
  height: 0;
}

.navbar_links.compact_mode.expanded .expanded_bg {
  background-color: rgba(0, 0, 0, 0.75);
}
/* #endregion */
</style>
